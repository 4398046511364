import { compact } from 'lodash';
import uri from 'urijs';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import throttleActions from 'redux-throttle';
import debounceActions from '@sarunas/redux-debounce';

import { addErrorState, addDebugState } from '../../common/store/debug-state/debug-state-actions';
import { createFastFormSubmitMiddleware } from '../../common/components/fast-form/store/fast-form-submit-middleware';
import { createMessageMiddleware } from '../../common/messages/framework/initialize-store';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
} from '../../common/modals/framework/initialize-store';
import createRequest from '../../common/services/create-request';
import createShowMessage from '../../common/messages/framework/create-show-message';
import {
  createDuplexerConnection,
  createDuplexerMiddleware,
  socketEventHandlers,
} from '../../common/middleware/duplexer-middleware';
import { modalResolverMapByType } from '../components/modals/modal-resolver-map-by-type';
import { modalDataLoaderByType } from '../components/modals/modal-data-loader-map-by-type';
import messageResolvers from '../components/messages/message-resolvers';
import { appUouBiMiddleware, appUsersBiMiddleware } from '../../common/bi-events/init-middleware';
import { eventMap } from '../bi-events/uou-events';
import { usersEventMap } from '../bi-events/users-events';
import { getInstance } from '../../common/controller/helpers';
import createRouterMiddleware from '../../common/middleware/create-router-middleware';

export function createReduxStore({
  reducers,
  appParams,
  wixCodeApi,
  compId,
  isDebug,
  fedopsLogger,
  platformAPIs,
  getRouter,
  language,
  isSSR,
  sentry,
  isEditor,
  isPreview,
}) {
  const p = {};

  function createRequestWithBaseUrl(baseUrl) {
    return createRequest({
      baseUrl,
      getInstance: getInstance(wixCodeApi),
      locale: language,
      trackError: error => p.store.dispatch(addErrorState(error)),
      logResponse: isDebug ? response => p.store.dispatch(addDebugState(response)) : undefined,
      petriOvr: wixCodeApi.location.query.petri_ovr,
      siteRevision: wixCodeApi.site.revision,
      isDebug,
    });
  }

  const origin = uri(wixCodeApi.location.baseUrl).origin();
  const baseUrls = appParams.baseUrls;

  const isBackend = wixCodeApi.window.rendering.env === 'backend';

  const baseUrl = isBackend ? `${origin}${baseUrls.apiBaseUrlClient}` : baseUrls.apiBaseUrlClient;

  const platformizedApiBaseUrl = isBackend
    ? `${origin}${baseUrls.apiPlatformizedBaseUrlClient}`
    : baseUrls.apiPlatformizedBaseUrlClient;

  const experimentsApiBaseUrl = isBackend
    ? `${origin}${baseUrls.apiExperimentsBaseUrlClient}`
    : baseUrls.apiExperimentsBaseUrlClient;

  const request = createRequestWithBaseUrl(baseUrl);
  const platformizedRequest = createRequestWithBaseUrl(platformizedApiBaseUrl);
  const experimentsRequest = createRequestWithBaseUrl(experimentsApiBaseUrl);

  const useDuplexer = !isSSR && !isEditor;

  const middleware = compact([
    throttleActions(),
    debounceActions(),
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      experimentsRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      getRouter,
    }),
    createFastFormSubmitMiddleware({}),
    (isEditor || isPreview) && createRouterMiddleware(getRouter),
    createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
    createModalOpenedMiddleware({ modalDataLoaderByType }),
    createMessageMiddleware({ resolvers: messageResolvers }),
    // createInteractionsMiddleware(fedopsLogger, {
    //   [CREATE_POST_SUCCESS]: CREATE_POST,
    //   [UPDATE_POST_SUCCESS]: UPDATE_POST,
    //   [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
    //   [DELETE_POST_SUCCESS]: DELETE_POST,
    //   [CREATE_COMMENT_SUCCESS]: CREATE_COMMENT,
    //   [UPDATE_COMMENT_SUCCESS]: UPDATE_COMMENT,
    //   [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: LIKE_COMMENT,
    //   [DELETE_COMMENT_SUCCESS]: DELETE_COMMENT,
    // }),
    isEditor
      ? appUsersBiMiddleware({ platformAPIs, instanceId: appParams.instanceId, eventMap: usersEventMap })
      : appUouBiMiddleware({ platformAPIs, instanceId: appParams.instanceId, eventMap }),
    useDuplexer &&
      createDuplexerMiddleware({
        duplexerConnection: createDuplexerConnection({
          duplexerServerUrl: baseUrls.duplexerUrl,
          blogApiBaseUrl: baseUrl,
          instance: getInstance(wixCodeApi)(),
        }),
        eventHandlers: socketEventHandlers,
        instanceId: appParams.instanceId,
        captureMessage: (message, extra) => sentry.captureException(message, { extra }),
      }),
  ]);

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
