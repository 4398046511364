import { PAGE_OPENED } from '../../actions/page-opened';
import { FEED_PAGE, CATEGORY_PAGE, POST_PAGE } from '../../services/detect-route';
import { categoryIds, isPublished } from '../event-helpers';

const handler = action => {
  switch (action.payload.page) {
    case FEED_PAGE: {
      return {
        evid: 200,
        eventMeta: {
          isNavigation: true,
          description: 'home page opened',
        },
      };
    }
    case CATEGORY_PAGE:
      return {
        evid: 201,
        category_id: action.payload.category._id,
        eventMeta: {
          isNavigation: true,
          description: 'category page opened',
        },
      };
    case POST_PAGE: {
      const { post } = action.payload;

      return {
        evid: 202,
        post_id: post._id,
        category_ids: categoryIds(post),
        is_published: isPublished(post),
        eventMeta: {
          isNavigation: true,
          description: 'post page opened',
        },
      };
    }
    default:
      return;
  }
};

export const uouPageOpenedEvent = { [PAGE_OPENED]: handler };
